@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	overflow-x: hidden;
	max-width: 100vw;
	max-height: 100vh;
}

* {
	max-width: 100vw;
}

img {
	pointer-events: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

@keyframes marquee {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-50%);
	}
}

.animate-marquee {
	animation: marquee 15s linear infinite;
}
